<template>
  <div class="h100">
    <common-layout>
      <template #header>
        <el-form inline>
          <el-form-item label="创建时间">
            <el-date-picker v-model="time" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" start-placeholder="开始时间" end-placeholder="结束时间" clearable> </el-date-picker>
          </el-form-item>
          <el-form-item label="通知标题">
            <el-input v-model="form.title" placeholder="请输入" clearable></el-input>
          </el-form-item>
          <el-form-item label="通知类型">
            <el-select v-model="form.subModel" clearable>
              <el-option v-for="item in noticeTypeData" :key="item.dictionaryValue" :value="item.dictionaryValue" :label="item.dictionaryLabel"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="query">查询</el-button>
            <el-button type="primary" plain @click="handleAdd">新增通知</el-button>
          </el-form-item>
        </el-form>
      </template>
      <template #default>
        <el-table :data="tableData" v-loading="tableLoading" border height="100%">
          <el-table-column prop="title" label="通知标题" align="center" min-width="150px" />
          <el-table-column prop="subModel" label="通知类型" align="center" min-width="150px">
            <template slot-scope="scope">{{ getModelName(scope.row.subModel) }}</template>
          </el-table-column>
          <el-table-column prop="hasSend" label="状态" align="center" min-width="120px">
            <template slot-scope="scope">
              <el-link :type="scope.row.hasSend ? 'success' : 'danger'" style="font-size: 12px">{{ scope.row.hasSend ? '已发送' : '未发送' }}</el-link>
            </template>
          </el-table-column>
          <el-table-column prop="force" label="用户不可屏蔽" align="center" min-width="120px">
            <template slot-scope="scope">
              <el-link :type="scope.row.force ? 'success' : 'danger'">{{ scope.row.force ? '是' : '否' }}</el-link>
            </template>
          </el-table-column>
          <el-table-column prop="createUser" label="创建人" align="center" min-width="120px" />
          <el-table-column prop="createTime" label="创建时间" align="center" min-width="140px" />
          <el-table-column label="操作" align="center" width="140px" fixed="right">
            <template slot-scope="scope">
              <el-button type="text" @click="handleInfo(scope.row)"> 详情 </el-button>
              <el-button type="text" @click="handleEdit(scope.row)" v-if="!scope.row.hasSend"> 编辑 </el-button>
              <el-button type="text" @click="handleSend(scope.row)" v-if="!scope.row.hasSend"> 发送 </el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <template #footer>
        <el-pagination class="mt10" :page-size="page.size" :current-page="page.current" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" layout="total, sizes, prev, pager, next, jumper"></el-pagination>
      </template>
    </common-layout>
    <add-modal :show.sync="showAdd" :detail="curRow" :operaterType="operaterType" :noticeTypeData="noticeTypeData" @success="query" />
  </div>
</template>
<script>
import api from '@/api/operate/notice'
import addModal from './addModal'
export default {
  components: { addModal },
  data() {
    return {
      time: [],
      form: {},
      tableData: [],
      tableLoading: false,
      showAdd: false,
      noticeTypeData: [],
      curRow: {},
      operaterType: 'add',
      page: {
        current: 1,
        size: 20,
      },
      total: 0,
    }
  },
  async mounted() {
    await this.getNoticeType()
    this.query()
  },
  methods: {
    async getNoticeType() {
      const { data } = await api.getNoticeType('system_notice')
      this.noticeTypeData = data
    },
    query() {
      let params = {
        ...this.form,
        ...this.page,
        startTime: this.time?.length > 1 ? this.time[0] : null,
        endTime: this.time?.length > 1 ? this.time[1] : null,
      }
      this.tableLoading = true
      api
        .query(params)
        .then(({ data }) => {
          this.tableData = data.records
          this.total = data.total
        })
        .finally(() => {
          this.tableLoading = false
        })
    },
    getModelName(code) {
      let item = _.find(this.noticeTypeData, ['dictionaryValue', code])
      return item ? item.dictionaryLabel : ''
    },
    handleAdd() {
      this.operaterType = 'add'
      this.curRow = {}
      this.showAdd = true
    },
    handleEdit(row){
      this.operaterType = 'edit'
      this.curRow = {...row}
      this.showAdd = true
    },
    handleInfo(row) {
      this.operaterType = 'info'
      this.curRow = { ...row }
      this.showAdd = true
    },
    handleSend(row) {
      this.$confirm('是否确定操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        api.send({ id: row.id }).then(() => {
          this.$message.success('操作成功')
          this.query()
        })
      })
    },
    handleSizeChange(val) {
      this.page.size = val
      this.page.current = 1
      this.query()
    },
    handleCurrentChange(val) {
      this.page.current = val
      this.query()
    },
  },
}
</script>
