<template>
  <el-dialog :title="titleObj[operaterType]" width="1000px" :visible="show" @close="close" :close-on-click-modal="false" top="30px">
    <el-form label-width="90px" :model="form" :rules="rules" ref="form">
      <el-row>
        <el-col :span="12">
          <el-form-item label="通知标题" prop="title">
            <el-input placeholder="请输入" v-model="form.title" clearable :disabled="operaterType == 'info'"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="通知类型" prop="subModel">
            <el-select v-model="form.subModel" :disabled="operaterType == 'info'">
              <el-option v-for="item in noticeTypeData" :key="item.dictionaryValue" :value="item.dictionaryValue" :label="item.dictionaryLabel"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="通知内容" prop="content">
            <el-input placeholder="请输入" v-model="form.content" clearable type="textarea" :disabled="operaterType == 'info'" :rows="4"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="立即发送">
            <el-switch v-model="form.hasSend" :disabled="operaterType == 'info'"> </el-switch>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="用户不可屏蔽">
            <el-switch v-model="form.force" :disabled="operaterType == 'info'"> </el-switch>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="发送给所有人">
            <el-switch v-model="form.all" :disabled="operaterType == 'info'"> </el-switch>
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="!form.all">
          <el-form-item label="租户" prop="receiveTenantCode">
            <el-select v-model="form.receiveTenantCode" filterable @change="tenantChange" :disabled="operaterType == 'info'" clearable>
              <el-option v-for="item in tenantList" :key="item.tenantCode" :label="item.tenantCode" :value="item.tenantCode"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item :label="operaterType == 'add' ? '选择用户' : '已选用户'" v-if="!form.all">
        <div class="flex">
          <div class="flex-1 w-0 mr20" v-if="operaterType != 'info'">
            <div class="common-header">用户列表</div>
            <el-form inline>
              <el-form-item label="">
                <el-input v-model="searchForm.name" placeholder="请输入昵称" clearable></el-input>
              </el-form-item>
              <el-form-item label="">
                <el-input v-model="searchForm.account" placeholder="请输入账号" clearable></el-input>
              </el-form-item>
              <el-form-item label="">
                <el-button type="primary" @click="getUserList">查询</el-button>
                <el-button type="primary" :disabled="!multTableList.length" @click="handleBatchAdd">批量添加</el-button>
              </el-form-item>
            </el-form>
            <el-table :data="userList" border @selection-change="handleSelectionChange" height="220px" ref="multipleTable1" v-loading="tableLoading">
              <el-table-column type="selection" width="55"> </el-table-column>
              <el-table-column label="昵称" prop="name" align="center"></el-table-column>
              <el-table-column label="账号" prop="account" align="center"></el-table-column>
              <el-table-column label="操作" align="center" width="90">
                <template slot-scope="scope">
                  <el-button type="text" @click="handleAdd(scope.row)">添加</el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination class="mt10" :page-size="page.size" :current-page="page.current" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" layout="total, sizes, prev, pager, next, jumper"></el-pagination>
          </div>
          <div style="width: 400px">
            <div class="common-header" v-if="operaterType == 'add'">已选用户</div>
            <el-table :data="form.receiveAccountsList" border height="280px">
              <el-table-column label="昵称" prop="name" align="center" v-if="operaterType == 'add'"></el-table-column>
              <el-table-column label="账号" prop="account" align="center"></el-table-column>
              <el-table-column label="操作" align="center" width="60" v-if="operaterType != 'info'">
                <template slot-scope="scope">
                  <el-button type="text" @click="handleDel(scope.$index)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-form-item>
    </el-form>
    <el-dialog title="接收统计详情" :visible.sync="showRead" :close-on-click-modal="false" append-to-body @close="showRead = false">
      <el-form label-width="150px" v-loading="readLoading">
        <el-form-item label="已读数量：">
          <span class="link">{{ readData.readCount }}</span>
        </el-form-item>
        <el-form-item label="未读数量：">
          <span class="link">{{ readData.unReadCount }}</span>
        </el-form-item>
        <el-form-item label="总量：">
          <span class="link">{{ readData.total }}</span>
        </el-form-item>
        <el-form-item label="已读百分比：">
          <span class="link">{{ readData.readPercent }} %</span>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="showRead = false">取消</el-button>
      </div>
    </el-dialog>
    <div slot="footer">
      <el-button type="warning" @click="seeRead" v-if="operaterType == 'info'">查看接收统计</el-button>
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="save" v-if="operaterType != 'info'">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import api from '@/api/operate/notice'
export default {
  props: {
    show: { default: false, type: Boolean },
    operaterType: { type: String, default: 'add' },
    detail: { type: Object, default: () => {} },
    noticeTypeData: { type: Array, default: () => [] },
  },
  data() {
    return {
      form: {},
      searchForm: {},
      rules: {
        title: [{ required: true, message: '请输入通知标题', trigger: 'blur' }],
        subModel: [{ required: true, message: '请选择通知类型', trigger: 'change' }],
        content: [{ required: true, message: '请输入通知内容', trigger: 'blur' }],
        receiveTenantCode: [{ required: true, message: '请选择租户', trigger: 'change' }],
      },
      tenantList: [],
      multTableList: [],
      tableLoading: false,
      page: {
        current: 1,
        size: 20,
      },
      total: 0,
      userList: [],
      titleObj: {
        add: '新增通知',
        edit: '修改通知',
        info: '详情',
      },
      showRead: false,
      readData: {},
      readLoading: false
    }
  },
  watch: {
    show(val) {
      if (val) {
        this.readData = {}
        this.userList = []
        this.multTableList = []
        this.searchForm = []
        this.$set(this.page, 'current', 1)
        if (this.operaterType == 'add') {
          this.form = { all: true, force: true, hasSend: true, receiveAccountsList: [] }
        } else {
          this.form = this.detail
          if (!this.form.all) {
            this.$set(this.form, 'receiveAccountsList', [])
            this.form?.receiveAccounts?.forEach((item) => {
              this.form.receiveAccountsList.push({ account: item })
            })
            this.operaterType == 'edit' && this.getUserList()
          }
        }
        this.$refs['form']?.resetFields()
        this.getTenantList()
      }
    },
  },
  methods: {
    // 查看接收统计
    async seeRead() {
      this.showRead = true
      try{
        this.readLoading = true
        const { data } = await api.getReadAnalyse({ id: this.detail.id })
      this.readData = data || {}
      let readPercent = 0
      if (data.readCount && data.total) {
        readPercent = ((data.readCount / data.total) * 100).toFixed(2)
      }
      this.$set(this.readData, 'readPercent', readPercent)
      }finally{
        this.readLoading = false
      }
     
    },
    // 获取租户
    getTenantList() {
      api.getTenant().then(({ data }) => {
        this.tenantList = data
      })
    },
    tenantChange() {
      this.$set(this.form, 'receiveAccountsList', [])
      this.$set(this.page, 'current', 1)
      this.getUserList()
    },
    // 获取用户数据
    async getUserList() {
      if (!this.form.receiveTenantCode) {
        this.userList = []
        return
      }
      let param = {
        ...this.page,
        ...this.searchForm,
        tenantCode: this.form.receiveTenantCode,
        useStatus: true,
      }
      try {
        this.tableLoading = true
        const { data } = await api.getUserList(param)
        this.userList = data.records
        this.total = data.total
      } finally {
        this.tableLoading = false
      }
    },
    close() {
      this.$emit('update:show', false)
    },
    save() {
      this.$refs['form'].validate((valid) => {
        if (!valid) return
        let params = {
          ...this.form,
        }
        if (params.all) {
          delete params.receiveTenantCode
          delete params.receiveAccountsList
          delete params.receiveAccounts
        } else {
          params.receiveAccounts = _.map(params.receiveAccountsList, 'account')
          delete params.receiveAccountsList
        }
        api.add(params).then(() => {
          this.$msg.success('操作成功')
          this.$emit('success')
          this.close()
        })
      })
    },
    handleBatchAdd() {
      this.multTableList.forEach((item) => {
        this.handleAdd(item)
      })
      this.$refs.multipleTable1?.clearSelection()
    },
    handleAdd(row) {
      let item = _.find(this.form.receiveAccountsList, ['account', row.account])
      if (!item) {
        this.form.receiveAccountsList.push(row)
      }
    },
    handleDel(index) {
      this.form.receiveAccountsList.splice(index, 1)
    },
    handleSelectionChange(val) {
      this.multTableList = val
    },
    handleSizeChange(val) {
      this.page.size = val
      this.page.current = 1
      this.getUserList()
    },
    handleCurrentChange(val) {
      this.page.current = val
      this.getUserList()
    },
  },
}
</script>
