import http from '@/utils/axios'
const api = {
  query: (data) => http.post(`/edt-notice/backend/notice/queryPage`, data),
  add: (data) => http.post('/edt-notice/backend/notice/add', data),
  send: (data) => http.post('/edt-notice/backend/notice/send', data),
  getNoticeType: (type) => http.get(`/eds/system/dictionary/data/type/${type}`),
  getTenant: () => http.get('/tenant-user/enterpriseAccount/back_end/all'),
  getUserList: (data) => http.post('/tenant-user/user/back_end/all', data),
  getReadAnalyse: (data) => http.post('/edt-notice/backend/notice/readAnalyse', data)
}
export default api
